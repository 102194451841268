import validate from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@bugsnag+core@7.22.7_@nuxt+devtools@1.3.1_@unocss+reset@0.60_75ngenvetdolqzlprkrmbvsgdq/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@bugsnag+core@7.22.7_@nuxt+devtools@1.3.1_@unocss+reset@0.6_rreswyzc67z2oom3ndgmtjxwkm/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts")
}
import { default as calculateFvApTTqyvXMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/calculatietool/pages/calculate.vue?macro=true";
import { default as indexrjve6021laMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/calculatietool/pages/index.vue?macro=true";
export default [
  {
    name: calculateFvApTTqyvXMeta?.name ?? "calculate",
    path: calculateFvApTTqyvXMeta?.path ?? "/calculate",
    meta: calculateFvApTTqyvXMeta || {},
    alias: calculateFvApTTqyvXMeta?.alias || [],
    redirect: calculateFvApTTqyvXMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/calculatietool/pages/calculate.vue").then(m => m.default || m)
  },
  {
    name: indexrjve6021laMeta?.name ?? "index",
    path: indexrjve6021laMeta?.path ?? "/",
    meta: indexrjve6021laMeta || {},
    alias: indexrjve6021laMeta?.alias || [],
    redirect: indexrjve6021laMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/calculatietool/pages/index.vue").then(m => m.default || m)
  }
]